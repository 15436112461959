<template>
    <main>
         <div class="hero" :style="{ backgroundImage: `linear-gradient(rgba(0 0 0 / 0.3), rgba(0 0 0 / 0.3)), url(${(formImagesItem.image!==null?formImagesItem.image:formImagesItemDefault.image)})` }">
           <div v-if="formImagesItem" class="container container--xs text-center">
                <h1>{{formImagesItem.title}}</h1>
                <p>{{formImagesItem.subtitle}}</p>
           </div>
        </div>
        <section class="section pt-0">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Cómo ganar puntos</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Cómo ganar puntos</h2>
                <div class="py-lg pt-0">
                    <p>¡Ahora eres parte del CLUB BARRINGTON! Sigue recomendando productos Barrington a tus clientes, ¡nosotros iremos sumando puntos a tu cuenta para que puedas canjear grandes premios! ¡A más metros, más puntos!</p>

                    <p>Cada producto tiene una equivalencia en puntos. A medida que acumulas puntos, podrás ascender de nivel y gozarás de más y mejores beneficios. Los puntos no expiran. ¡Puedes usar tus puntos cuando prefieras!</p>

                </div>
                <div class="table table--text">
                    <table class="table__content">

                        <HeadLevel :dataLevel="dataLevel" :withLabel="true" />
                        <tr v-for="(pointForExchange, key) in dataPointForExchange" :key="key">
                            <td><div><strong>{{ pointForExchange.name }}</strong></div></td>
                            <td v-for="(item, key2) in pointForExchange.items" :key="key2">{{ item.point }}</td>
                        
                        </tr>
                    </table>
                </div>
            </div>
        </section>
    </main>
    
</template>

<script>
import { ref, computed } from "vue";
import router from "@/router"
import API from "@/api";
import HeadLevel from "@/components/HeadLevel";

export default {
    name: 'HowEarnPoint',
    components: {
        HeadLevel
    },

    setup(props, context) {
    const dataLevel = ref([])
    const dataStateLevel = ref(null)
    const dataPointForExchange = ref([])
    const dataStatePointForExchange = ref(null)
    const baseURL = API.defaults.baseURL
    const baseURLStatic = API.defaults.baseURLStatic

    const getPointForExchange = () => {
        dataStatePointForExchange.value = 'loading'
        return API.get('/api/point-for-exchange')
          .then(response => {
            dataStatePointForExchange.value = 'success'
            dataPointForExchange.value = response.data
            
          })
      }          
    
    const getLevel = () => {
        dataStateLevel.value = 'loading'
        return API.get('/api/level')
          .then(response => {
            dataStateLevel.value = 'success'
            dataLevel.value = response.data
            
          })
      }

        const formImagesItem = ref([]);
        const formImagesItemDefault = ref([]);
        const getDefault = () =>{
            API.get("/api/head-global-page-internal").then(response => { 
                formImagesItemDefault.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL +  response.data[0].image;
            })
        }
        const getHeaderDetail = () => {      
            API.get('/api/image-head-page').then(response => {      
                formImagesItem.value = response.data.filter(x=> x.head_menu_navigation_detail===2)[0];
                    console.log('x',formImagesItem.value)

                if (formImagesItem.value!=undefined){
                    formImagesItem.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL + formImagesItem.value.image;
                }
            });
        } 

    return { router, dataPointForExchange, dataLevel, getPointForExchange, getLevel, baseURL, baseURLStatic, formImagesItem, getHeaderDetail, formImagesItemDefault, getDefault }

    },
    created () {
        this.getLevel()
        this.getPointForExchange()
    },
    mounted() {
        this.getDefault()
        this.getHeaderDetail();
    }
    
}
</script>